import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "antd";
import Image from "../ImageQuerys/LocalImages";

const XtraLargeSection = (props) => {
  const { data, nav } = props;
  return (
    <div className="support-area" id={nav}>
      <Row gutter={[36, 36]} align="middle">
        <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24}>
          <Image imageName="support-guy.png" style={{ height: "100%" }} />
        </Col>
        <Col span={12} sm={24} md={24} lg={12} xl={12} xs={24}>
          <h4>Your local support team</h4>
          <h2 className="">
            {data.mainTitle}
            <span className="blue_dot">.</span>
          </h2>
          <p className="content" style={{ paddingRight: "72px" }}>
            {data.subtext}
          </p>
          <div className="mobile-center">
            <a href={`tel:${data.phoneNumber}`} className="btn-solid-dark">
              {data.phoneText}
            </a>
            <a href={`mailto:${data.email}`} className="btn-skeleton-dark">
              Send an email
            </a>
          </div>
        </Col>
      </Row>
      <style jsx global>{`
        .support-area {
          position: relative;
          overflow: hidden;
          background: #f3f4f6;
          border-radius: 12px;
          padding: 75px;
          margin: 100px 30px 200px;
        }
        .support-area h4 {
          font-weight: 800;
          font-size: 14px;
          color: #090d12;
        }
        .support-area h2 {
          font-weight: 800;
          font-size: 38px;
          color: #090d12;
          line-height: 145%;
        }
        .support-area p {
          font-weight: normal;
          font-size: 16px;
          line-height: 190%;
          letter-spacing: -0.02em;
          color: #596779 !important;
          margin-bottom: 48px;
        }
        .support-area .btn-solid-dark {
          padding: 12px 16px;
          background: #1769ff;
          border-radius: 100px;
          color: #fff;
          font-weight: 800;
          font-size: 18px;
          margin-right: 12px;
        }
        .support-area .btn-skeleton-dark {
          font-weight: 4400;
          font-size: 18px;
          line-height: 132.7%;
          text-decoration: underline;
          color: #090d12;
        }
        @media screen and (max-width: 600px) {
          .support-area {
            padding: 16px;
            margin: 32px;
          }
          .support-area h4 {
            text-align: center;
          }
          .support-area h2 {
            text-align: center;
          }
          .support-area p {
            text-align: center;
            padding-right: 0 !important;
          }
          .mobile-center {
            display: grid !important;
            grid-template-columns: 1fr !important;
            align-items: center !important;
          }
          .mobile-center a {
            text-align: center;
            margin-bottom: 32px;
          }
        }
      `}</style>
    </div>
  );
};

XtraLargeSection.propTypes = {
  nav: PropTypes.string,
  data: PropTypes.string,
};
XtraLargeSection.defaultProps = {
  nav: "",
  data: "",
};

export default XtraLargeSection;
